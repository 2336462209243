<template>
  <div class="zee-containerr">
    <div class="zee-program-launch-card">
      <div class="zee-launch-text">
        <h1 class="zee-text">
          Is there any New Program Launch <br />
          planned in the next 8 weeks?
        </h1>
        <div class="zee-program-lauch-buttons">
          <zee-button
            :icon="'check'"
            class="zee-individual-button"
            @onClick="handleClick"
            title="Yes"
          ></zee-button>
          <zee-button
            :icon="'circle-x'"
            class="zee-individual-button"
            @onClick="handleClick"
            title="No"
          ></zee-button>
        </div>
      </div>
    </div>

    <program-launch-modal :modalShow="modalShow" @closeModal="modalShow = false" />
  </div>
</template>

<script>
import { Button, ProgramLaunchModal } from "../components";

export default {
  name: "ProgramLaunch",
  components: {
    "zee-button": Button,
    "program-launch-modal": ProgramLaunchModal,
  },
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    handleClick(e) {
      let buttonValue = e.target.innerText.trim();
      switch (buttonValue) {
        case "Yes":
          sessionStorage.setItem("show_add_program_screen", false);
          this.handleRouting("program-details");
          break;
        case "No":
          sessionStorage.setItem("show_add_program_screen", true);
          // this.handleRouting("program-plans");
          this.handleRouting("program-launch-details");
          // this.modalShow = true;
          break;
        default:
          break;
      }
    },
    handleRouting(route) {
      this.$router.push(`/solutions/zeetv/${route}`);
    },
    createDataSchema() {
      let programData = [
        {
          programGenre: "",
          programName: "",
          programLaunchDate: "",
          programRuntime: {
            startTime: "",
            endTime: "",
          },
          programFrequency: [],
          programNumberOfWeeks: "",
          programTargetGRPs: {
            min: "",
            max: "",
          },
          campaignDistribution: [],
          recommendedDistribution: [],
        },
      ];
      let selectedDay = null;
      sessionStorage.removeItem("selectedRowId");
      sessionStorage.removeItem("weeks");
      sessionStorage.removeItem("programLaunchDate")
      sessionStorage.removeItem("backFromOutputs")
      localStorage.setItem("programDetails", JSON.stringify(programData));
      localStorage.setItem("selectedDay", selectedDay);
    },
  },
  created() {
    this.createDataSchema();
  },
};
</script>

<style scoped>
.zee-containerr {
  display: flex;
  justify-content: center; /* align horizontal */
  align-items: center; /* align vertical */
  height: 100vh;
}
.zee-program-launch-card {
  background-color: #6c757d;
  width: 70%;
  height: 250px;
  border-radius: 15px;
}
.zee-launch-text {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 80px;
}
.zee-text {
  text-align: center;
  font-size: 2.1rem;
  font-family: ProximaNovaBold;
  color: #ffffff;
}
.zee-program-lauch-buttons {
  display: flex;
  flex-direction: row;
  gap: 5rem;
  margin-top: 35px;
}
.zee-individual-button {
  background-color: #ffffff;
  color: #000000;
}

.zee-individual-button:hover {
  background-color: #e0e0e0;
}
.zee-icons {
  fill: #000000;
}
</style>
